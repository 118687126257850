import React, { FC } from 'react'

import { useWS } from '../../providers/WSProvider'
import { useRequest } from '../../providers/RequestProvider'
import { Codebox } from '..'
import { CopyIcon } from '../../icons'

import styles from './index.module.less'
import Button from '../Button'

interface WSPanelProps {}

enum States {
	'CONNECTING',
	'OPEN',
	'CLOSING',
	'CLOSED',
}

const WSPanel: FC<WSPanelProps> = () => {
	const [ws, setWS] = useWS()
	const [request, setRequest] = useRequest()

	const handleConnect = (): void => {
		if (ws.connection && ws.connection.readyState !== States.CLOSED) {
			ws.connection.close()
			setWS((o) => ({ ...o, error: 'Connection closed by user.' }))
			return
		}
		const connection = new WebSocket(ws.endpoint)
		setWS((o) => ({ ...o, connection, error: null }))
	}

	const copy = (value: string): void => {
		if (!request.body?.length) {
			setRequest((o) => ({ ...o, body: [{ parameters: [{ enabled: true, key: 'callId', value }] }] }))
			return
		}

		const existingCallIdParameter = request.body[0]?.parameters?.find(({ key }) => key === 'callId')
		if (existingCallIdParameter) {
			existingCallIdParameter.value = value
		} else {
			request.body[0].parameters.push({ enabled: true, key: 'callId', value })
		}

		setRequest((o) => ({ ...o, body: request.body || [] }))
	}

	const copyButton = (s: string): JSX.Element => (
		<button className={styles.uniqueId} onClick={(): void => copy(s.match(/: "(.*?)"/)[1])}>
			UniqueId&nbsp;
			<CopyIcon />
		</button>
	)

	const value = ws.messages
		.map((m) => {
			try {
				return JSON.stringify(JSON.parse(m), null, 4)
			} catch {
				return m
			}
		}, null)
		.join('\n\n')
		.split('"UniqueId"')
		.reduce((messages, s) => {
			if (messages.length) {
				messages.push(copyButton(s))
			}
			messages.push(s)
			return messages
		}, [])

	return (
		<div className={styles.position} style={{ left: `calc(100vw - ${ws.isVisible ? styles['w-panel'] : '0px'})` }}>
			<div className={styles.wrapper}>
				<div className={styles.row}>
					<input
						tabIndex={-1}
						spellCheck={false}
						value={ws.endpoint}
						className={styles.endpoint}
						onChange={({ target: { value: endpoint } }): void => setWS((o) => ({ ...o, endpoint }))}
					/>
					<Button className={styles.connect} onClick={handleConnect}>
						{ws.connection && ws.connection.readyState !== States.CLOSED ? 'Close' : 'Connect'}
					</Button>
				</div>
				<div className={styles.output}>
					<div className={styles.info}>
						<div>Status: {ws.connection ? States[ws.connection.readyState] : 'CLOSED'}</div>
						{ws.error && <div>Error: {ws.error}</div>}
					</div>
					<div className={styles.codebox}>
						<Codebox value={value} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default WSPanel
