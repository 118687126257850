import React, { FC } from 'react'

import Button from '../../../Button'
import { PlusIcon } from '../../../../icons'
import styles from '../../index.module.less'

interface NewKeyValuePairButtonProps {
	clickHandler: () => void
}

const NewKeyValuePairButton: FC<NewKeyValuePairButtonProps> = ({ clickHandler }) => (
	<div className={styles.add}>
		<Button className={styles.button} onClick={clickHandler}>
			<PlusIcon />
			<span className={styles.text}>New key/value pair</span>
		</Button>
	</div>
)

export default NewKeyValuePairButton
