import React, { FC } from 'react'

import { useWS } from '../../providers/WSProvider'
import { Arrow } from '..'

import styles from './index.module.less'
import Button from '../Button'

const WS: FC = () => {
	const [WS, setWS] = useWS()
	return (
		<div className={styles.ws}>
			<Button onClick={(): void => setWS(o => ({ ...o, isVisible: !o.isVisible }))} className={styles.wsButton}>
				<Arrow toggled={!WS.isVisible} />
				<span className={styles.text}>WSS</span>
			</Button>
		</div>
	)
}

export default WS
