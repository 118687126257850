import React, { useContext, createContext, FC, useState } from 'react'
import { FormValue } from '../types'
import globalHeaders from '../globalHeaders.json'

type GlobalHeadersContext = [FormValue[], React.Dispatch<React.SetStateAction<FormValue[]>>]

interface GlobalHeadersProviderProps {
	children?: React.ReactNode
}

const GlobalHeadersProvider: FC<GlobalHeadersProviderProps> = (props) => {
	const [headers, setHeaders] = useState<FormValue[]>(globalHeaders)
	return <GlobalHeaders.Provider value={[headers, setHeaders]} {...props} />
}

const GlobalHeaders = createContext<GlobalHeadersContext>(null)

const useGlobalHeaders = (): GlobalHeadersContext => useContext<GlobalHeadersContext>(GlobalHeaders)

export { GlobalHeadersProvider, useGlobalHeaders }
