// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper___IcV4T {\n  display: flex;\n  align-items: center;\n}\n.wrapper___IcV4T > input,\n.wrapper___IcV4T > input + div {\n  font-family: monospace;\n  font-size: 1rem;\n  margin: 0.25rem 0 0.25rem 0.5rem;\n  padding: 0.5rem;\n  width: 100%;\n  max-width: 20rem;\n}\n.wrapper___IcV4T > input:first-child,\n.wrapper___IcV4T > input + div:first-child {\n  width: 1rem;\n  margin-left: 0;\n}\n.wrapper___IcV4T > input.valueInput___fCkat,\n.wrapper___IcV4T > input + div.valueInput___fCkat {\n  max-width: 40rem;\n}\n.wrapper___IcV4T > input + div {\n  padding: 0;\n}\n.remove___mp3us {\n  padding: 0.5rem;\n  color: #d50000;\n  cursor: pointer;\n  user-select: none;\n}\n", "",{"version":3,"sources":["webpack://./src/components/FormPair/index.module.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,mBAAA;AAUD;AAZA;;EAKE,sBAAA;EACA,eAAA;EACA,gCAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;AAWF;AAVE;;EACC,WAAA;EACA,cAAA;AAaH;AAXE;;EACC,gBAAA;AAcH;AA9BA;EAoBE,UAAA;AAaF;AATA;EACC,eAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;AAWD","sourcesContent":["@import '~variables';\n\n.wrapper {\n\tdisplay: flex;\n\talign-items: center;\n\t> input,\n\t> input + div {\n\t\tfont-family: @font-family-monospace;\n\t\tfont-size: @font-size-md;\n\t\tmargin: @width-sm 0 @width-sm @width-md;\n\t\tpadding: @width-md;\n\t\twidth: 100%;\n\t\tmax-width: @width-sider;\n\t\t&:first-child {\n\t\t\twidth: @width-xl;\n\t\t\tmargin-left: 0;\n\t\t}\n\t\t&.valueInput {\n\t\t\tmax-width: @width-sider + 20;\n\t\t}\n\t}\n\t> input + div {\n\t\tpadding: 0;\n\t}\n}\n\n.remove {\n\tpadding: @width-md;\n\tcolor: @color-danger;\n\tcursor: pointer;\n\tuser-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GET": "#21c921",
	"POST": "#ffbd1d",
	"DELETE": "#d50000",
	"PUT": "#7f00ff",
	"PATCH": "#2b22aa",
	"WS": "#245cf7",
	"DIALTEST": "#c11cc1",
	"w-sider": "20rem",
	"w-panel": "24rem",
	"wrapper": "wrapper___IcV4T",
	"valueInput": "valueInput___fCkat",
	"remove": "remove___mp3us"
};
export default ___CSS_LOADER_EXPORT___;
