// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper___ayysy {\n  height: 100%;\n  position: relative;\n  padding-left: 2rem;\n  background-color: #f6f6f6;\n  border-radius: 0.25rem;\n}\n.output___vZAlc {\n  font-family: monospace;\n  background-color: #fefefe;\n  white-space: pre;\n  height: 100%;\n  overflow: scroll;\n  width: 100%;\n  min-height: 30rem;\n  padding: 0 0.25rem;\n  line-height: 1rem;\n}\n.output___vZAlc::selection {\n  background-color: #ececec;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Codebox/index.module.less"],"names":[],"mappings":"AAEA;EACC,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,sBAAA;AAUD;AAPA;EACC,sBAAA;EACA,yBAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;AASD;AARC;EACC,yBAAA;AAUF","sourcesContent":["@import '~variables';\n\n.wrapper {\n\theight: 100%;\n\tposition: relative;\n\tpadding-left: 2 * @width-xl;\n\tbackground-color: @background-light;\n\tborder-radius: @width-sm;\n}\n\n.output {\n\tfont-family: monospace;\n\tbackground-color: @background-lighter;\n\twhite-space: pre;\n\theight: 100%;\n\toverflow: scroll;\n\twidth: 100%;\n\tmin-height: 30 * @width-xl;\n\tpadding: 0 @width-sm;\n\tline-height: @line-height;\n\t&::selection {\n\t\tbackground-color: darken(@background-light, 4);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GET": "#21c921",
	"POST": "#ffbd1d",
	"DELETE": "#d50000",
	"PUT": "#7f00ff",
	"PATCH": "#2b22aa",
	"WS": "#245cf7",
	"DIALTEST": "#c11cc1",
	"w-sider": "20rem",
	"w-panel": "24rem",
	"wrapper": "wrapper___ayysy",
	"output": "output___vZAlc"
};
export default ___CSS_LOADER_EXPORT___;
