import React, { FC, ReactElement, useState } from 'react'
import axios, { AxiosError, AxiosResponse } from 'axios'

import { QueryBar, Tabs, Layout, Request, Output, WSPanel, WSToggle, Head } from './components'
import { useRequest, RequestProvider } from './providers/RequestProvider'
import { WSProvider } from './providers/WSProvider'
import BrandingContext from './providers/BrandingProvider'

import styles from './index.module.less'
import { FormBody, FormValue, TabData } from './types'
import { GlobalHeadersProvider, useGlobalHeaders } from './providers/GlobalHeadersProvider'
import { AppBranding } from './branding/types'

interface StringObject {
	[key: string]: string
}

const App: FC = () => {
	const [request] = useRequest()
	const [globalHeaders, setGlobalHeaders] = useGlobalHeaders()
	const [output, setOutput] = useState<AxiosResponse | AxiosError>()

	const tabs: TabData[] = [
		{
			name: 'Request Body',
			content: <Request.Body />,
		},
		{
			name: 'Request Headers',
			content: <Request.Headers />,
		},
		{
			name: 'Global Headers',
			content: <Request.GlobalHeaders />,
		},
	]

	const formValueFilter = (formValue: FormValue): boolean => formValue.enabled && formValue.key !== ''

	const getBody = (body: FormBody[]): string =>
		body
			.map((group) => [...group.parameters])
			.flat()
			.filter(formValueFilter)
			.map(({ key, value }) => [key, value].map(encodeURIComponent).join('='))
			.join('&')

	const getHeaders = (headers: FormValue[]): StringObject =>
		headers.filter(formValueFilter).reduce<StringObject>((headers, header) => {
			headers[header.key] = header.value
			return headers
		}, {})

	const executeRequest = (): Promise<void> => {
		const { endpoint, base, ...options } = request
		return axios(`${base}${endpoint}`, {
			method: options.method,
			headers: getHeaders([...(options.headers || []), ...globalHeaders]),
			...(options.method !== 'GET' && {
				data: getBody(options.body || []) || undefined,
			}),
		})
			.then((response) => {
				// [CAH-776 && CAH-808] - Need add Token to global headers when present.
				if (response.status === 200 && response.data?.Token) {
					setGlobalHeaders((prev) => {
						const headers = [...prev]

						let tokenHeaderIndex = headers.findIndex((header) => header.key === 'Token')
						if (tokenHeaderIndex > -1) {
							headers[tokenHeaderIndex].value = response.data.Token
							return headers
						}

						return [...headers, { enabled: true, key: 'Token', value: response.data.Token }]
					})
				}

				setOutput(response)
			})
			.catch(setOutput)
	}

	return (
		<>
			<Head />
			<Layout>
				<div className={styles.queryBar}>
					<QueryBar executeRequest={executeRequest} />
					<WSToggle />
				</div>
				<Request.Description />
				<Tabs data={tabs} />
				{output ? <Output output={output} /> : null}
			</Layout>
			<WSPanel />
		</>
	)
}

const AppWithProviders: FC<{ branding: AppBranding }> = ({ branding }): ReactElement => (
	<BrandingContext.Provider value={branding}>
		<WSProvider>
			<RequestProvider>
				<GlobalHeadersProvider>
					<App />
				</GlobalHeadersProvider>
			</RequestProvider>
		</WSProvider>
	</BrandingContext.Provider>
)

export default AppWithProviders
