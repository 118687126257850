import React, { FC, useState } from 'react'

import styles from './index.module.less'
import { TabData } from '../../types'

interface TabsProps {
	data: TabData[]
}

const Tabs: FC<TabsProps> = props => {
	const [activeIndex, setActiveIndex] = useState<number>(0)

	return (
		<div className={styles.wrapper}>
			<div className={styles.tabs}>
				{props.data.map((tab, i) => (
					<button
						key={i}
						className={[styles.tabButton, ...(activeIndex === i ? [styles.active] : [])].join(' ')}
						onClick={(): void => setActiveIndex(i)}
					>
						{tab.name}
					</button>
				))}
			</div>
			<div className={styles.tabContent}>{props.data[activeIndex].content}</div>
		</div>
	)
}

export default Tabs
