import { AppBranding } from './types'

const branding: AppBranding = {
	name: 'Integrator',
	apiFeatures: new Set([
		'Register',
		'Unload',
		'MakeCall',
		'Ping',
		'OwnerContact',
		'Answer',
		'Hangup',
		'TransferComplete',
		'TransferCancel',
		'SendDtmf',
		'TransferConsult',
		'TransferBlind',
		'Deflect',
		'Unhold',
		'Hold',
		'Pickup',
		'Version'
	]),
	dialTestingFeatures: new Set([])
}

export default branding
