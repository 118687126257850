import React, { FC } from 'react'
import { useGlobalHeaders } from '../../../providers/GlobalHeadersProvider'
import FormPair from '../../FormPair'

import styles from '../index.module.less'
import { FormValue } from '../../../types'
import Button from '../../Button'
import { PlusIcon } from '../../../icons'

const GlobalHeaders: FC = () => {
	const [headers, setHeaders] = useGlobalHeaders()

	const handleChange = (value: FormValue, index: number): void => {
		setHeaders(v => {
			const clone = [...v]
			clone[index] = value
			return clone
		})
	}
	const handleRemove = (index: number): void => {
		setHeaders(v => {
			const clone = [...v]
			clone.splice(index, 1)
			return clone
		})
	}

	const addFormPair = (): void => {
		setHeaders(v => [...v, { enabled: true, key: '', value: '' }])
	}

	return (
		<div>
			<div className={styles.fieldNames}>
				<span>Header Key</span>
				<span>Header Value</span>
			</div>
			{headers.map((value, i) => (
				<FormPair key={i} onRemove={handleRemove} onChange={handleChange} value={value} index={i} />
			))}
			<div className={styles.add}>
				<Button onClick={addFormPair}>
					<PlusIcon />
					&nbsp;New global header
				</Button>
			</div>
		</div>
	)
}

export default GlobalHeaders
