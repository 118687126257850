import React, { FC } from 'react'
import { FormPair, Button } from '../..'
import { useRequest } from '../../../providers/RequestProvider'

import styles from '../index.module.less'
import { FormValue } from '../../../types'
import { PlusIcon } from '../../../icons'

const Headers: FC = () => {
	const [request, setRequest] = useRequest()

	const handleChange = (value: FormValue, index: number): void => {
		setRequest(v => {
			const headers = [...v.headers]
			headers[index] = value
			return { ...v, headers }
		})
	}

	const handleRemove = (index: number): void => {
		setRequest(v => {
			const headers = [...v.headers]
			headers.splice(index, 1)
			return { ...v, headers }
		})
	}

	const addFormPair = (): void => {
		setRequest(v => ({ ...v, headers: [...(v.headers || []), { enabled: true, key: '', value: '' }] }))
	}

	return (
		<div>
			<div className={styles.fieldNames}>
				<span>Header Key</span>
				<span>Header Value</span>
			</div>
			{request.headers?.map((value, i) => (
				<FormPair key={i} onRemove={handleRemove} onChange={handleChange} value={value} index={i} />
			))}
			<div className={styles.add}>
				<Button onClick={addFormPair}>
					<PlusIcon />
					&nbsp;New header
				</Button>
			</div>
		</div>
	)
}

export default Headers
