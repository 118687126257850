import React, { FC, useRef, useEffect, useState, ReactElement } from 'react'

import endpoints from '../../../endpoints.json'
import dialTesting from '../../../dialTesting.json'
import { useRequest } from '../../../providers/RequestProvider'
import BrandingContext from '../../../providers/BrandingProvider'

import styles from './index.module.less'
import { EndpointRequest } from '../../../types'

const Endpoints: FC = () => {
	const [scrollTop, setScrollTop] = useState<number>(0)
	const [request, setRequest] = useRequest()

	const container = useRef<HTMLDivElement>(null)

	const updateRequest = (options: EndpointRequest): void => {
		setRequest((o) => {
			if (request.shouldCopyCallIdOnResponse && request.callId) {
				const callIdParameter = options.body?.[0]?.parameters?.find(({ key }) => key === 'callId')
				if (callIdParameter) {
					callIdParameter.value = request.callId
				}
			}

			return { ...o, ...options, body: options.body || [] }
		})
	}

	useEffect(() => {
		const node = container.current

		const handleScroll = (e: Event): void => setScrollTop((e.target as HTMLDivElement).scrollTop)
		node.addEventListener('scroll', handleScroll)
		return (): void => node.removeEventListener('scroll', handleScroll)
	}, [])

	return (
		<div
			ref={container}
			className={styles.endpoints}
			style={{ boxShadow: scrollTop ? `inset 0px 11px ${styles['w-md']} -10px #CCC, inset 0px -11px ${styles['w-md']} -10px #CCC` : undefined }}
		>
			<BrandingContext.Consumer>
				{({ apiFeatures }): ReactElement[] =>
					Array.from(apiFeatures).map((feature) => (
						<div
							key={feature}
							className={[styles.item, ...(request.id === endpoints[feature].id ? [styles.active] : [])].join(' ')}
							onClick={(): void => updateRequest(endpoints[feature])}
						>
							<div style={{ color: styles[endpoints[feature].method] }} className={styles.method}>
								{endpoints[feature].method}
							</div>
							<span>{feature}</span>
						</div>
					))
				}
			</BrandingContext.Consumer>
			<BrandingContext.Consumer>
				{({ dialTestingFeatures }): ReactElement[] =>
					Array.from(dialTestingFeatures).map((feature) => (
						<a key={feature} className={styles.item} href={`${dialTesting[feature].action}:${dialTesting[feature].number}`}>
							<div style={{ color: styles.DIALTEST }} className={styles.method}>
								{feature.toUpperCase()}
							</div>
							<span>{dialTesting[feature].number}</span>
						</a>
					))
				}
			</BrandingContext.Consumer>
		</div>
	)
}

export default Endpoints
