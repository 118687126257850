import React, { FC } from 'react'
import styles from './index.module.less'

interface DropdownProps {
	options: string[]
	onChange(value: string): void
	selected?: string
}

const Dropdown: FC<DropdownProps> = (props: DropdownProps) => (
	<select defaultValue={props.selected} className={styles.Dropdown} onChange={(e) => props.onChange(e.target.value)}>
		{props.options.map((option, i) => (
			<option value={option} key={i}>
				{option}
			</option>
		))}
	</select>
)

export default Dropdown
