import React from 'react'
import ReactDOM from 'react-dom/client'

import './assets/less/index.less'
import App from './App'
import { AppBranding } from './branding/types'

const renderWithBranding = (branding: AppBranding): void => {
	const root = ReactDOM.createRoot(document.getElementById('app'))
	root.render(<App branding={branding} />)
}

export default renderWithBranding
