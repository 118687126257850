import React, { FC, useState } from 'react'
import { FormValue } from '../../types'

import styles from './index.module.less'
import Checkbox from '../Checkbox'
import Dropdown from '../Dropdown'

interface FormPairProps {
	index: number
	value: FormValue
	onChange(FormValue: FormValue, index: number): void
	onRemove(index: number): void
}

const FormPair: FC<FormPairProps> = (props) => {
	const [hovered, setHovered] = useState<boolean>(false)

	const handleChange = (key: keyof FormValue, value: any): void => {
		props.onChange({ ...props.value, [key]: value }, props.index)
	}

	const handleRemoveClick = (): void => props.onRemove(props.index)

	const renderInput = (): JSX.Element => {
		switch (props.value.type) {
			case 'select':
				return <Dropdown selected={props.value.value} onChange={(value) => handleChange('value', value)} options={props.value.options} />
			case 'boolean':
				return <Checkbox onToggle={(checked): void => handleChange('value', checked.toString())} checked={props.value.value === 'true'} />
			default:
				return (
					<input
						className={styles.valueInput}
						spellCheck={false}
						value={props.value.value}
						placeholder={props.value.placeholder}
						type={props.value.type ?? 'text'}
						onChange={(e): void => handleChange('value', e.target.value)}
					/>
				)
		}
	}

	return (
		<div className={styles.wrapper} onMouseEnter={(): void => setHovered(true)} onMouseLeave={(): void => setHovered(false)}>
			<Checkbox onToggle={(checked): void => handleChange('enabled', checked)} checked={props.value.enabled} />
			<input spellCheck={false} value={props.value.key} onChange={(e): void => handleChange('key', e.target.value)} />
			{renderInput()}
			{hovered ? (
				<div className={styles.remove} onClick={handleRemoveClick}>
					Remove
				</div>
			) : null}
		</div>
	)
}

export default FormPair
