import React, { FC, MouseEvent } from 'react'
import styles from './index.module.less'

interface ButtonProps {
	children?: React.ReactNode
	onClick?(e: React.MouseEvent): void
	className?: string
	type?: 'button' | 'submit' | 'reset'
}

const Button: FC<ButtonProps> = ({ children, className, ...props }) => (
	<button {...props} className={[styles.button, className].join(' ')}>
		{children}
	</button>
)

export default Button
