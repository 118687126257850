import React, { FC, ReactElement } from 'react'

import Endpoints from './Endpoints'
import { useWS } from '../../providers/WSProvider'

import styles from './index.module.less'
import BrandingContext from '../../providers/BrandingProvider'

interface LayoutProps {
	children?: React.ReactNode
}

const Layout: FC<LayoutProps> = (props) => {
	const [ws] = useWS()
	return (
		<div className={styles.wrapper}>
			<div className={styles.sider}>
				<BrandingContext.Consumer>
					{({ name }): ReactElement => <div className={styles.title}>{name} API Explorer</div>}
				</BrandingContext.Consumer>
				<Endpoints />
			</div>
			<div className={[styles.children, ...(ws.isVisible ? [styles.visible] : [])].join(' ')}>{props.children}</div>
		</div>
	)
}

export default Layout
