// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dropdown___IB08c {\n  padding: 0.5rem;\n  height: 100%;\n  cursor: pointer;\n  border-radius: 2px;\n  border: 1px #e0e0e0 solid;\n  width: 40rem;\n  margin: 0.25rem 0 0.25rem 0.5rem;\n  font-family: monospace;\n  font-size: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Dropdown/index.module.less"],"names":[],"mappings":"AAEA;EACC,eAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,gCAAA;EACA,sBAAA;EACA,eAAA;AAUD","sourcesContent":["@import '~variables';\n\n.Dropdown {\n\tpadding: @width-md;\n\theight: 100%;\n\tcursor: pointer;\n\tborder-radius: 2px;\n\tborder: 1px @background-dark solid;\n\twidth: @width-sider + 20;\n\tmargin: @width-sm 0 @width-sm @width-md;\n\tfont-family: @font-family-monospace;\n\tfont-size: @font-size-md;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GET": "#21c921",
	"POST": "#ffbd1d",
	"DELETE": "#d50000",
	"PUT": "#7f00ff",
	"PATCH": "#2b22aa",
	"WS": "#245cf7",
	"DIALTEST": "#c11cc1",
	"w-sider": "20rem",
	"w-panel": "24rem",
	"Dropdown": "Dropdown___IB08c"
};
export default ___CSS_LOADER_EXPORT___;
