import React, { FC, ReactElement } from 'react'
import { Helmet } from 'react-helmet'
import BrandingContext from '../../providers/BrandingProvider'

const Head: FC = () => (
	<BrandingContext.Consumer>
		{({ name }): ReactElement => (
			<Helmet>
				<meta charSet='utf-8' />
				<title>{name} API Explorer</title>
				<meta name='viewport' content='width=device-width, initial-scale=1' />
				<link rel='shortcut icon' type='image/x-icon' href='/favicon-32x32.png' />
			</Helmet>
		)}
	</BrandingContext.Consumer>
)

export default Head
