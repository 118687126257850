import React, { FC } from 'react'
import ReactMarkdown from 'react-markdown'

import { useRequest } from '../../../providers/RequestProvider'
import styles from '../../../index.module.less'

const Description: FC = () => {
	const [request] = useRequest()
	return request.description ? (
		<div className={styles.wrapper}>
			<ReactMarkdown>{request.description}</ReactMarkdown>
		</div>
	) : null
}

export default Description
