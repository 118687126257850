import React, { FC } from 'react'
import { useRequest } from '../../../providers/RequestProvider'
import { FormPair, Checkbox } from '../..'

import NewKeyValuePairButton from './NewKeyValuePairButton'
import styles from '../index.module.less'

import { FormValue } from '../../../types'

const Body: FC = () => {
	const [request, setRequest] = useRequest()
	const groupHasEnabledItems = (i: number) => request.body?.[i]?.parameters?.some((parameter) => parameter.enabled)

	const handleChange = (value: FormValue, bodyIndex: number, valueIndex: number): void => {
		setRequest((v) => {
			const body = [...v.body]
			body[bodyIndex].parameters[valueIndex] = value
			return { ...v, body }
		})
	}

	const handleRemoveFromGroup = (bodyIndex: number, valueIndex: number): void => {
		setRequest((prev) => {
			const newRequest = { ...prev }
			newRequest.body[bodyIndex].parameters.splice(valueIndex, 1)
			return newRequest
		})
	}

	const addFormPairToGroup = (bodyIndex: number): void => {
		setRequest((prev) => {
			const newRequest = { ...prev }
			newRequest.body[bodyIndex].parameters.push({ enabled: true, key: '', value: '' })
			return newRequest
		})
	}

	const createGroupWithFormPair = (): void => {
		setRequest((prev) => {
			const newRequest = { ...prev }
			newRequest.body = [{ parameters: [{ enabled: true, key: '', value: '' }] }]
			return newRequest
		})
	}

	const toggleAllGroup = (bodyIndex: number): void => {
		setRequest((prev) => {
			const newRequest = { ...prev }
			newRequest.body[bodyIndex].parameters = newRequest.body[bodyIndex].parameters.map((parameter) => ({
				...parameter,
				enabled: !groupHasEnabledItems(bodyIndex),
			}))
			return newRequest
		})
	}

	return (
		<>
			{request.body?.map((group, groupIndex) => (
				<div key={groupIndex}>
					{group.title && <h3>{group.title}</h3>}
					<div className={`${styles.fieldNames} ${request.body ? styles.noMargin : ''}`}>
						{request.body && <Checkbox checked={groupHasEnabledItems(groupIndex)} onToggle={() => toggleAllGroup(groupIndex)} />}
						<span>Key</span>
						<span>Value</span>
					</div>
					{group.parameters?.map((value, i) => (
						<FormPair
							key={`${groupIndex}-${i}`}
							onRemove={() => handleRemoveFromGroup(groupIndex, i)}
							onChange={(value, valueIndex) => handleChange(value, groupIndex, valueIndex)}
							value={value}
							index={i}
						/>
					))}
					<NewKeyValuePairButton clickHandler={() => addFormPairToGroup(groupIndex)} />
				</div>
			))}
			{(!request.body || request.body.length === 0) && <NewKeyValuePairButton clickHandler={createGroupWithFormPair} />}
		</>
	)
}

export default Body
