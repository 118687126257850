// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".item___s9hXg {\n  background-color: #fefefe;\n  padding: 1rem;\n  margin: 1rem;\n  border-radius: 0.25rem;\n  box-shadow: 1px 1px 2px 2px #e5e5e5;\n}\n.wrapper___MBEuG {\n  margin: 1rem;\n  min-height: 30rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Output/index.module.less"],"names":[],"mappings":"AAEA;EACC,yBAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,mCAAA;AAUD;AAPA;EACC,YAAA;EACA,iBAAA;AASD","sourcesContent":["@import '~variables';\n\n.item {\n\tbackground-color: @background-lighter;\n\tpadding: @width-xl;\n\tmargin: @width-xl;\n\tborder-radius: @width-sm;\n\tbox-shadow: @box-shadow;\n}\n\n.wrapper {\n\tmargin: @width-xl;\n\tmin-height: 30 * @width-xl;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GET": "#21c921",
	"POST": "#ffbd1d",
	"DELETE": "#d50000",
	"PUT": "#7f00ff",
	"PATCH": "#2b22aa",
	"WS": "#245cf7",
	"DIALTEST": "#c11cc1",
	"w-sider": "20rem",
	"w-panel": "24rem",
	"item": "item___s9hXg",
	"wrapper": "wrapper___MBEuG"
};
export default ___CSS_LOADER_EXPORT___;
