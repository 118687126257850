import React, { FC, useRef, useEffect } from 'react'

import styles from './index.module.less'

interface BodyProps {
	value: React.ReactNode[]
}

const Body: FC<BodyProps> = props => {
	const textarea = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (textarea.current) {
			textarea.current.scrollTop = textarea.current.scrollHeight
		}
	}, [props.value])

	return (
		<div className={styles.wrapper}>
			<div ref={textarea} spellCheck={false} className={styles.output}>
				{props.value}
			</div>
		</div>
	)
}

export default Body
