import React, { FC } from 'react'

import styles from './index.module.less'

interface ArrowProps {
	toggled?: boolean
}

const Arrow: FC<ArrowProps> = props => (
	<svg
		className={[styles.arrow, props.toggled ? styles.rotate : undefined].join(' ')}
		fill='#ffffff'
		xmlns='http://www.w3.org/2000/svg'
		width='12'
		height='12'
		viewBox='0 0 24 24'
	>
		<path d='M6 0l12 12-12 12z' />
	</svg>
)

export default Arrow
