import React, { FC, useState, useEffect } from 'react'
import { AxiosResponse, AxiosError } from 'axios'

import { Codebox } from '..'
import { useRequest } from '../../providers/RequestProvider'

import styles from './index.module.less'

interface OutputProps {
	output: AxiosResponse | AxiosError | Error
}

const Output: FC<OutputProps> = (props) => {
	const [, setRequest] = useRequest()
	const [output, setOutput] = useState(null)

	// Formatting JSON response in output
	useEffect(() => {
		if (props.output && 'data' in props.output) {
			try {
				props.output.data = JSON.parse(props.output.data)
			} catch (_) {}

			const { output } = props
			if (typeof output.data === 'object') {
				if ('UniqueId' in output.data) {
					setRequest((o) => ({ ...o, callId: output.data.UniqueId }))
				}

				props.output.data = JSON.stringify(props.output.data, null, 4)
			}
		}
		setOutput(props.output)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.output])

	if (!output) return null

	return 'data' in output ? (
		<>
			<div className={styles.item}>
				{output.status} - {output.statusText}
			</div>
			<div className={styles.wrapper}>
				<Codebox value={output.data} />
			</div>
		</>
	) : (
		<div className={styles.item}>
			{output.name} - {output.message}
		</div>
	)
}

export default Output
