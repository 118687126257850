// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button___n4efS {\n  display: flex;\n  padding: 0.25rem;\n  align-items: center;\n  border: none;\n  cursor: pointer;\n  border-radius: 2px;\n  background-color: #1e90ff;\n  color: white;\n  white-space: nowrap;\n  font-size: 1rem;\n  font-family: 'Roboto', sans-serif;\n  font-weight: 300;\n  user-select: none;\n}\n.button___n4efS:hover {\n  background-color: #0483ff;\n}\n.button___n4efS:active {\n  background-color: #0077ea;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Button/index.module.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,YAAA;EACA,eAAA;EACA,kBAAA;EACA,yBAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;EACA,iCAAA;EACA,gBAAA;EACA,iBAAA;AAUD;AATC;EACC,yBAAA;AAWF;AATC;EACC,yBAAA;AAWF","sourcesContent":["@import '~variables';\n\n.button {\n\tdisplay: flex;\n\tpadding: @width-sm;\n\talign-items: center;\n\tborder: none;\n\tcursor: pointer;\n\tborder-radius: 2px;\n\tbackground-color: @color-primary;\n\tcolor: white;\n\twhite-space: nowrap;\n\tfont-size: @font-size-md;\n\tfont-family: @font-family-normal;\n\tfont-weight: @font-weight-light;\n\tuser-select: none;\n\t&:hover {\n\t\tbackground-color: darken(@color-primary, 5%);\n\t}\n\t&:active {\n\t\tbackground-color: darken(@color-primary, 10%);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GET": "#21c921",
	"POST": "#ffbd1d",
	"DELETE": "#d50000",
	"PUT": "#7f00ff",
	"PATCH": "#2b22aa",
	"WS": "#245cf7",
	"DIALTEST": "#c11cc1",
	"w-sider": "20rem",
	"w-panel": "24rem",
	"button": "button___n4efS"
};
export default ___CSS_LOADER_EXPORT___;
