import React, { FC } from 'react'

import styles from './index.module.less'

interface CheckboxProps {
	checked?: boolean
	className?: string
	label?: React.ReactNode
	onToggle?(checked: boolean): void
}

const Checkbox: FC<CheckboxProps> = props => (
	<div className={styles.wrapper}>
		{props.label && <span className={styles.label}>{props.label}</span>}
		<div
			onClick={(): void => props.onToggle && props.onToggle(!props.checked || false)}
			className={[props.className, styles.box, ...(props.checked ? [styles.active] : [])].join(' ')}
		>
			<svg viewBox='0 0 24 24' width='20' height='20' xmlns='http://www.w3.org/2000/svg' clipRule='evenodd'>
				<path d='M 0 12 L 8 20 L 24 4' />
			</svg>
		</div>
	</div>
)

export default Checkbox
