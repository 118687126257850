// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fieldNames____zegi {\n  display: flex;\n  margin-left: calc(calc(0.25rem * 2 + 20px) + 0.5rem);\n  padding-bottom: 0.25rem;\n  user-select: none;\n}\n.fieldNames____zegi > span {\n  margin-left: 0.5rem;\n  max-width: 20rem;\n  width: 100%;\n}\n.fieldNames____zegi > span:first-child {\n  margin-left: 0;\n}\n.noMargin___ogGIs {\n  margin-left: 0;\n}\n.add___SeggL {\n  padding-top: 0.25rem;\n  padding-left: calc(calc(0.25rem * 2 + 20px) + 0.5rem);\n}\n.text___uYGQk {\n  margin-left: 0.25rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Request/index.module.less"],"names":[],"mappings":"AAEA;EACC,aAAA;EASA,oDAAA;EACA,uBAAA;EACA,iBAAA;AAED;AAdA;EAGE,mBAAA;EAIA,gBAAA;EACA,WAAA;AAWF;AAfE;EACC,cAAA;AAiBH;AAPA;EACC,cAAA;AASD;AANA;EACC,oBAAA;EACA,qDAAA;AAQD;AALA;EACC,oBAAA;AAOD","sourcesContent":["@import '~variables';\n\n.fieldNames {\n\tdisplay: flex;\n\t> span {\n\t\tmargin-left: @width-md;\n\t\t&:first-child {\n\t\t\tmargin-left: 0;\n\t\t}\n\t\tmax-width: @width-sider;\n\t\twidth: 100%;\n\t}\n\tmargin-left: calc(@width-checkbox + @width-md);\n\tpadding-bottom: @width-sm;\n\tuser-select: none;\n}\n\n.noMargin {\n\tmargin-left: 0;\n}\n\n.add {\n\tpadding-top: @width-sm;\n\tpadding-left: calc(@width-checkbox + @width-md);\n}\n\n.text {\n\tmargin-left: @width-sm;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GET": "#21c921",
	"POST": "#ffbd1d",
	"DELETE": "#d50000",
	"PUT": "#7f00ff",
	"PATCH": "#2b22aa",
	"WS": "#245cf7",
	"DIALTEST": "#c11cc1",
	"w-sider": "20rem",
	"w-panel": "24rem",
	"fieldNames": "fieldNames____zegi",
	"noMargin": "noMargin___ogGIs",
	"add": "add___SeggL",
	"text": "text___uYGQk"
};
export default ___CSS_LOADER_EXPORT___;
