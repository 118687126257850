import React, { useContext, createContext, FC, useState, useEffect } from 'react'

type WSState = {
	endpoint: string
	isVisible: boolean
	messages: any[]
	connection: WebSocket
	error: string
}

type RequestContext = [WSState, React.Dispatch<React.SetStateAction<WSState>>]

interface WSProviderProps {
	children?: React.ReactNode
}

const WSProvider: FC<WSProviderProps> = (props) => {
	const [ws, setWS] = useState<WSState>({
		isVisible: false,
		messages: [],
		endpoint: 'wss://localhost:10443/api/v1/events',
		connection: null,
		error: null,
	})

	useEffect(() => {
		if (ws.connection) {
			ws.connection.onmessage = (e): void => {
				setWS((o) => {
					o.messages.push(e.data)
					return { ...o }
				})
			}
			ws.connection.onopen = (): void => setWS((o) => ({ ...o }))
			ws.connection.onclose = (): void => setWS((o) => ({ ...o }))
			ws.connection.onerror = (): void => setWS((o) => ({ ...o, error: 'Connection closed unexpectedly.' }))
		}
	}, [ws.connection])

	return <Request.Provider value={[ws, setWS]} {...props} />
}

const Request = createContext<RequestContext>(null)

const useWS = (): RequestContext => useContext<RequestContext>(Request)

export { WSProvider, useWS }
