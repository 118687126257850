// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper___HNYyF {\n  background-color: #fefefe;\n  padding: 1rem;\n  margin: 1rem;\n  border-radius: 0.25rem;\n  box-shadow: 1px 1px 2px 2px #e5e5e5;\n}\n.row___eg_Wm {\n  display: flex;\n  justify-content: space-between;\n}\n.queryBar___iyfrf {\n  display: flex;\n  margin: 1rem;\n  margin-right: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/index.module.less"],"names":[],"mappings":"AAEA;EACC,yBAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,mCAAA;AAUD;AAPA;EACC,aAAA;EACA,8BAAA;AASD;AANA;EACC,aAAA;EACA,YAAA;EACA,eAAA;AAQD","sourcesContent":["@import '~variables';\n\n.wrapper {\n\tbackground-color: @background-lighter;\n\tpadding: @width-xl;\n\tmargin: @width-xl;\n\tborder-radius: @width-sm;\n\tbox-shadow: @box-shadow;\n}\n\n.row {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n\n.queryBar {\n\tdisplay: flex;\n\tmargin: @width-xl;\n\tmargin-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GET": "#21c921",
	"POST": "#ffbd1d",
	"DELETE": "#d50000",
	"PUT": "#7f00ff",
	"PATCH": "#2b22aa",
	"WS": "#245cf7",
	"DIALTEST": "#c11cc1",
	"w-sider": "20rem",
	"w-panel": "24rem",
	"wrapper": "wrapper___HNYyF",
	"row": "row___eg_Wm",
	"queryBar": "queryBar___iyfrf"
};
export default ___CSS_LOADER_EXPORT___;
