// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ws___DoW9q {\n  z-index: 2;\n  display: flex;\n  align-items: center;\n  background-color: #fefefe;\n  padding: 1rem;\n  margin-left: 1rem;\n  border-top-left-radius: 0.25rem;\n  border-bottom-left-radius: 0.25rem;\n  box-shadow: 1px 1px 2px 2px #e5e5e5;\n}\n.wsButton___NVTSl {\n  padding: 0.5rem;\n  background-color: #245cf7;\n}\n.wsButton___NVTSl:hover {\n  background-color: #0b4af6;\n}\n.wsButton___NVTSl:active {\n  background-color: #0841e0;\n}\n.text___BJ63n {\n  margin-left: 0.25rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/WSToggle/index.module.less"],"names":[],"mappings":"AAEA;EACC,UAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,aAAA;EACA,iBAAA;EACA,+BAAA;EACA,kCAAA;EACA,mCAAA;AAUD;AAPA;EACC,eAAA;EACA,yBAAA;AASD;AARC;EACC,yBAAA;AAUF;AARC;EACC,yBAAA;AAUF;AANA;EACC,oBAAA;AAQD","sourcesContent":["@import '~variables';\n\n.ws {\n\tz-index: 2;\n\tdisplay: flex;\n\talign-items: center;\n\tbackground-color: @background-lighter;\n\tpadding: @width-xl;\n\tmargin-left: @width-xl;\n\tborder-top-left-radius: @width-sm;\n\tborder-bottom-left-radius: @width-sm;\n\tbox-shadow: @box-shadow;\n}\n\n.wsButton {\n\tpadding: @width-md;\n\tbackground-color: @color-blue;\n\t&:hover {\n\t\tbackground-color: darken(@color-blue, 5%);\n\t}\n\t&:active {\n\t\tbackground-color: darken(@color-blue, 10%);\n\t}\n}\n\n.text {\n\tmargin-left: @width-sm;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GET": "#21c921",
	"POST": "#ffbd1d",
	"DELETE": "#d50000",
	"PUT": "#7f00ff",
	"PATCH": "#2b22aa",
	"WS": "#245cf7",
	"DIALTEST": "#c11cc1",
	"w-sider": "20rem",
	"w-panel": "24rem",
	"ws": "ws___DoW9q",
	"wsButton": "wsButton___NVTSl",
	"text": "text___BJ63n"
};
export default ___CSS_LOADER_EXPORT___;
