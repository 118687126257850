import React, { useContext, createContext, FC, useState } from 'react'

import { EndpointRequest } from '../types'

type RequestContext = [EndpointRequest, React.Dispatch<React.SetStateAction<EndpointRequest>>]

interface RequestProviderProps {
	children?: React.ReactNode
}

const RequestProvider: FC<RequestProviderProps> = (props) => {
	const [request, setRequest] = useState<EndpointRequest>({
		method: 'GET',
		base: 'https://localhost:10443/api/v1/',
		endpoint: '',
		shouldCopyCallIdOnResponse: true,
		callId: null,
	})
	return <Request.Provider value={[request, setRequest]} {...props} />
}

const Request = createContext<RequestContext>(null)

const useRequest = (): RequestContext => useContext<RequestContext>(Request)

export { RequestProvider, useRequest }
