import React, { FC, useState } from 'react'

import { useRequest } from '../../providers/RequestProvider'
import { Loading } from '..'
import styles from './index.module.less'
import { Method } from '../../types'
import Button from '../Button'
import { SettingsIcon } from '../../icons'

interface QueryBarProps {
	executeRequest(): Promise<void>
}

interface SettingsModalProps {
	onSave(value: string): void
	open: boolean
	initialValue: string
}
const SettingsModal: FC<SettingsModalProps> = ({ initialValue, onSave, open }) => {
	const [value, setValue] = useState(initialValue)
	const save = (e: React.FormEvent<HTMLFormElement>): void => {
		e.preventDefault()
		onSave(value)
	}
	return (
		<div className={`${styles.overlay} ${open ? styles.show : ''}`}>
			<div className={styles.modalWrapper}>
				<div className={styles.modal}>
					<form onSubmit={save}>
						<h2>Configure Request Settings</h2>
						<h3>Base API URL</h3>
						<input className={styles.input} value={value} onChange={({ target: { value: base } }): void => setValue(base)} />
						<div className={styles.footer}>
							<Button type='submit'>Save</Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	)
}

const QueryBar: FC<QueryBarProps> = props => {
	const [request, setRequest] = useRequest()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [modalOpen, setModalOpen] = useState<boolean>(false)

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		if (e.key === 'Enter') runEndpoint()
	}

	const runEndpoint = (): void => {
		setIsLoading(true)
		props.executeRequest().then(() => setIsLoading(false))
	}

	const methods: Method[] = ['GET', 'POST', 'DELETE', 'PUT', 'PATCH']

	const saveSettings = (base: string): void => {
		base = base.endsWith('/') ? base : `${base}/`
		setRequest(o => ({ ...o, base }))
		setModalOpen(false)
	}

	return (
		<div className={styles.wrapper}>
			<SettingsModal open={modalOpen} onSave={saveSettings} initialValue={request.base} />
			<Button className={styles.settingsButton} onClick={(): void => setModalOpen(true)}>
				<SettingsIcon /> <span className={styles.text}>Configure Base URL</span>
			</Button>
			<select
				className={styles.select}
				style={{ backgroundColor: styles[request.method] }}
				onChange={({ target: { value: method } }: any): void => setRequest(o => ({ ...o, method }))}
				value={request.method}
			>
				{methods.map((el, i) => (
					<option key={i} value={el}>
						{el}
					</option>
				))}
			</select>
			<div className={styles.url}>{request.base}</div>
			<input
				onChange={({ target: { value: endpoint } }): void => setRequest(o => ({ ...o, endpoint }))}
				value={request.endpoint}
				placeholder='Input path or select an endpoint...'
				spellCheck={false}
				onKeyPress={handleKeyPress}
				className={styles.input}
				type='text'
			/>
			<Button onClick={runEndpoint}>Send</Button>
			{isLoading && <Loading size={6} />}
		</div>
	)
}

export default QueryBar
